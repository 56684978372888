export function validatePIN (nationalityId:number, pin:string) : boolean {
  switch (nationalityId) {
    case (92):
      return validateNL(pin).valid
    case (105):
      return validatePL(pin).valid
    case (7):
      return validateAR(pin).valid
    case (17):
      return validateBA(pin).valid
    case (18):
      return validateBR(pin).valid
    case (20):
      return validateBG(pin).valid
    case (27):
      return validateCO(pin).valid
    case (70):
      return validateHR(pin).valid
    case (128):
      return validateCZ(pin).valid
    case (129):
      return validateCZ(pin).valid
    case (32):
      return validateDK(pin).valid
    case (43):
      return validateFR(pin).valid
    case (51):
      return validateHK(pin).valid
    case (52):
      return validateIE(pin).valid
    case (53):
      return validateIS(pin).valid
    case (54):
      return validateID(pin).valid
    case (58):
      return validateIL(pin).valid
    case (68):
      return validateKR(pin).valid
    case (71):
      return validateLV(pin).valid
    case (76):
      return validateLT(pin).valid
    case (80):
      return validateMY(pin).valid
    case (84):
      return validateMX(pin).valid
    case (88):
      return validateME(pin).valid
    case (97):
      return validateNO(pin).valid
    case (104):
      return validatePE(pin).valid
    case (107):
      return validateRO(pin).valid
    case (112):
      return validateRS(pin).valid
    case (113):
      return validateRS(pin).valid
    case (115):
      return validateSI(pin).valid
    case (138):
      return validateZA(pin).valid
    case (118):
      return validateES(pin).valid
    case (139):
      return validateSE(pin).valid
    case (124):
      return validateTH(pin).valid
    case (126):
      return validateTH(pin).valid
    case (132):
      return validateUY(pin).valid
    default:
      console.log('Geen validatie voor geselecteerde land!')
      return true
  }
}

function validateNL (value:string) : { meta:unknown, valid:boolean } {
  if (value.length < 8) {
    return {
      meta: {},
      valid: false
    }
  }
  let v = value
  if (v.length === 8) {
    v = `0${v}`
  }
  if (!/^[0-9]{4}[.]{0,1}[0-9]{2}[.]{0,1}[0-9]{3}$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }
  v = v.replace(/\./g, '')
  if (parseInt(v, 10) === 0) {
    return {
      meta: {},
      valid: false
    }
  }
  let sum = 0
  const length = v.length
  for (let i = 0; i < length - 1; i++) {
    sum += (9 - i) * parseInt(v.charAt(i), 10)
  }
  sum = sum % 11
  if (sum === 10) {
    sum = 0
  }
  return {
    meta: {},
    valid: `${sum}` === v.charAt(length - 1)
  }
}

function validateAR (value:string) : { meta:unknown, valid:boolean } {
  // Replace dot with empty space
  const v = value.replace(/\./g, '')
  return {
    meta: {},
    valid: /^\d{7,8}$/.test(v)
  }
}

function validateBA (value:string) : { meta:unknown, valid:boolean } {
  return {
    meta: {},
    valid: jmbg(value, 'BA')
  }
}

function validateBR (value:string) : { meta:unknown, valid:boolean } {
  const v = value.replace(/\D/g, '')

  if (!/^\d{11}$/.test(v) || /^1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11}|0{11}$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }

  let d1 = 0
  let i
  for (i = 0; i < 9; i++) {
    d1 += (10 - i) * parseInt(v.charAt(i), 10)
  }
  d1 = 11 - d1 % 11
  if (d1 === 10 || d1 === 11) {
    d1 = 0
  }
  if (`${d1}` !== v.charAt(9)) {
    return {
      meta: {},
      valid: false
    }
  }

  let d2 = 0
  for (i = 0; i < 10; i++) {
    d2 += (11 - i) * parseInt(v.charAt(i), 10)
  }
  d2 = 11 - d2 % 11
  if (d2 === 10 || d2 === 11) {
    d2 = 0
  }

  return {
    meta: {},
    valid: `${d2}` === v.charAt(10)
  }
}

function validateBG (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{10}$/.test(value) && !/^\d{6}\s\d{3}\s\d{1}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const v = value.replace(/\s/g, '')
  // Check the birth date
  let year = parseInt(v.substr(0, 2), 10) + 1900
  let month = parseInt(v.substr(2, 2), 10)
  const day = parseInt(v.substr(4, 2), 10)
  if (month > 40) {
    year += 100
    month -= 40
  } else if (month > 20) {
    year -= 100
    month -= 20
  }

  if (!isValidDate(year, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  let sum = 0
  const weight = [2, 4, 8, 5, 10, 9, 7, 3, 6]
  for (let i = 0; i < 9; i++) {
    sum += parseInt(v.charAt(i), 10) * weight[i]
  }
  sum = (sum % 11) % 10
  return {
    meta: {},
    valid: `${sum}` === v.substr(9, 1)
  }
}

function validateCO (value:string) : { meta:unknown, valid:boolean } {
  const v = value.replace(/\./g, '').replace('-', '')
  if (!/^\d{8,16}$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }
  const length = v.length
  const weight = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71]
  let sum = 0
  for (let i = length - 2; i >= 0; i--) {
    sum += parseInt(v.charAt(i), 10) * weight[i]
  }
  sum = sum % 11
  if (sum >= 2) {
    sum = 11 - sum
  }
  return {
    meta: {},
    valid: `${sum}` === v.substr(length - 1)
  }
}

function validateHR (value:string) : { meta:unknown, valid:boolean } {
  return {
    meta: {},
    valid: (/^[0-9]{11}$/.test(value) && mod11And10(value))
  }
}

function validateCZ (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{9,10}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  let year = 1900 + parseInt(value.substr(0, 2), 10)
  const month = parseInt(value.substr(2, 2), 10) % 50 % 20
  const day = parseInt(value.substr(4, 2), 10)
  if (value.length === 9) {
    if (year >= 1980) {
      year -= 100
    }
    if (year > 1953) {
      return {
        meta: {},
        valid: false
      }
    }
  } else if (year < 1954) {
    year += 100
  }

  if (!isValidDate(year, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Check that the birth date is not in the future
  if (value.length === 10) {
    let check = parseInt(value.substr(0, 9), 10) % 11
    if (year < 1985) {
      check = check % 10
    }
    return {
      meta: {},
      valid: `${check}` === value.substr(9, 1)
    }
  }

  return {
    meta: {},
    valid: true
  }
}

function validateDK (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{6}[-]{0,1}[0-9]{4}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const v = value.replace(/-/g, '')
  const day = parseInt(v.substr(0, 2), 10)
  const month = parseInt(v.substr(2, 2), 10)
  let year = parseInt(v.substr(4, 2), 10)

  switch (true) {
    case ('5678'.indexOf(v.charAt(6)) !== -1 && year >= 58):
      year += 1800
      break
    case ('0123'.indexOf(v.charAt(6)) !== -1):
    case ('49'.indexOf(v.charAt(6)) !== -1 && year >= 37):
      year += 1900
      break
    default:
      year += 2000
      break
  }

  return {
    meta: {},
    valid: isValidDate(year, month, day)
  }
}

function validateFR (value:string) : { meta:unknown, valid:boolean } {
  let v = value.toUpperCase()
  if (!/^(1|2)\d{2}\d{2}(\d{2}|\d[A-Z]|\d{3})\d{2,3}\d{3}\d{2}$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }
  // The COG group can be 2 digits or 2A or 2B
  const cog = v.substr(5, 2)
  switch (true) {
    case /^\d{2}$/.test(cog): v = value; break
    case cog === '2A': v = `${value.substr(0, 5)}19${value.substr(7)}`; break
    case cog === '2B': v = `${value.substr(0, 5)}18${value.substr(7)}`; break
    default:
      return {
        meta: {},
        valid: false
      }
  }
  const mod = 97 - parseInt(v.substr(0, 13), 10) % 97
  const prefixWithZero = mod < 10 ? `0${mod}` : `${mod}`
  return {
    meta: {},
    valid: prefixWithZero === v.substr(13)
  }
}

function validateHK (value:string) : { meta:unknown, valid:boolean } {
  const v = value.toUpperCase()
  if (!/^[A-MP-Z]{1,2}[0-9]{6}[0-9A]$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const firstChar = v.charAt(0)
  const secondChar = v.charAt(1)
  let sum = 0
  let digitParts = v
  if (/^[A-Z]$/.test(secondChar)) {
    sum += 9 * (10 + alphabet.indexOf(firstChar))
    sum += 8 * (10 + alphabet.indexOf(secondChar))
    digitParts = v.substr(2)
  } else {
    sum += 9 * 36
    sum += 8 * (10 + alphabet.indexOf(firstChar))
    digitParts = v.substr(1)
  }

  const length = digitParts.length
  for (let i = 0; i < length - 1; i++) {
    sum += (7 - i) * parseInt(digitParts.charAt(i), 10)
  }
  const remaining = sum % 11
  const checkDigit = remaining === 0 ? '0' : (11 - remaining === 10 ? 'A' : `${11 - remaining}`)
  return {
    meta: {},
    valid: checkDigit === digitParts.charAt(length - 1)
  }
}

function validateIS (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{6}[-]{0,1}[0-9]{4}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const v = value.replace(/-/g, '')
  const day = parseInt(v.substr(0, 2), 10)
  const month = parseInt(v.substr(2, 2), 10)
  let year = parseInt(v.substr(4, 2), 10)
  const century = parseInt(v.charAt(9), 10)

  year = (century === 9) ? (1900 + year) : ((20 + century) * 100 + year)
  if (!isValidDate(year, month, day, true)) {
    return {
      meta: {},
      valid: false
    }
  }
  // Validate the check digit
  const weight = [3, 2, 7, 6, 5, 4, 3, 2]
  let sum = 0
  for (let i = 0; i < 8; i++) {
    sum += parseInt(v.charAt(i), 10) * weight[i]
  }
  sum = 11 - sum % 11
  return {
    meta: {},
    valid: `${sum}` === v.charAt(8)
  }
}

function validateID (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[2-9]\d{11}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const converted = value.split('').map((item) => parseInt(item, 10))
  return {
    meta: {},
    valid: verhoeff(converted)
  }
}

function validateIE (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{7}[A-W][AHWTX]?$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }

  const getCheckDigit = (v: string) => {
    let input = v
    while (input.length < 7) {
      input = `0${input}`
    }
    const alphabet = 'WABCDEFGHIJKLMNOPQRSTUV'
    let sum = 0
    for (let i = 0; i < 7; i++) {
      sum += parseInt(input.charAt(i), 10) * (8 - i)
    }
    sum += 9 * alphabet.indexOf(input.substr(7))
    return alphabet[sum % 23]
  }

  // 2013 format
  const isValid = (value.length === 9 && (value.charAt(8) === 'A' || value.charAt(8) === 'H'))
    ? value.charAt(7) === getCheckDigit(value.substr(0, 7) + value.substr(8) + '')
    // The old format
    : value.charAt(7) === getCheckDigit(value.substr(0, 7))
  return {
    meta: {},
    valid: isValid
  }
}

function validateIL (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{1,9}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }

  return {
    meta: {},
    valid: luhn(value)
  }
}

function validateKR (value:string) : { meta:unknown, valid:boolean } {
  const v = value.replace('-', '')
  if (!/^\d{13}$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Check the date of birth
  const sDigit = v.charAt(6)
  let year = parseInt(v.substr(0, 2), 10)
  const month = parseInt(v.substr(2, 2), 10)
  const day = parseInt(v.substr(4, 2), 10)
  switch (sDigit) {
    case '1':
    case '2':
    case '5':
    case '6':
      year += 1900
      break
    case '3':
    case '4':
    case '7':
    case '8':
      year += 2000
      break
    default:
      year += 1800
      break
  }
  if (!isValidDate(year, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Calculate the check digit
  const weight = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5]
  const length = v.length
  let sum = 0
  for (let i = 0; i < length - 1; i++) {
    sum += weight[i] * parseInt(v.charAt(i), 10)
  }

  const checkDigit = (11 - sum % 11) % 10
  return {
    meta: {},
    valid: `${checkDigit}` === v.charAt(length - 1)
  }
}

function validateLV (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{6}[-]{0,1}[0-9]{5}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const v = value.replace(/\D/g, '')
  // Check birth date
  const day = parseInt(v.substr(0, 2), 10)
  const month = parseInt(v.substr(2, 2), 10)
  let year = parseInt(v.substr(4, 2), 10)
  year = year + 1800 + parseInt(v.charAt(6), 10) * 100

  if (!isValidDate(year, month, day, true)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Check personal code
  let sum = 0
  const weight = [10, 5, 8, 4, 2, 1, 6, 3, 7, 9]
  for (let i = 0; i < 10; i++) {
    sum += parseInt(v.charAt(i), 10) * weight[i]
  }
  sum = (sum + 1) % 11 % 10
  return {
    meta: {},
    valid: `${sum}` === v.charAt(10)
  }
}

function validateLT (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{11}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const gender = parseInt(value.charAt(0), 10)
  let year = parseInt(value.substr(1, 2), 10)
  const month = parseInt(value.substr(3, 2), 10)
  const day = parseInt(value.substr(5, 2), 10)
  const century = (gender % 2 === 0) ? (17 + gender / 2) : (17 + (gender + 1) / 2)
  year = century * 100 + year
  if (!isValidDate(year, month, day, true)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Validate the check digit
  let weight = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1]
  let sum = 0
  let i
  for (i = 0; i < 10; i++) {
    sum += parseInt(value.charAt(i), 10) * weight[i]
  }
  sum = sum % 11
  if (sum !== 10) {
    return {
      meta: {},
      valid: `${sum}` === value.charAt(10)
    }
  }

  // Re-calculate the check digit
  sum = 0
  weight = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3]
  for (i = 0; i < 10; i++) {
    sum += parseInt(value.charAt(i), 10) * weight[i]
  }
  sum = sum % 11
  if (sum === 10) {
    sum = 0
  }
  return {
    meta: {},
    valid: `${sum}` === value.charAt(10)
  }
}

function validateMY (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{12}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  // Validate date of birth
  const year = parseInt(value.substr(0, 2), 10)
  const month = parseInt(value.substr(2, 2), 10)
  const day = parseInt(value.substr(4, 2), 10)
  if (!isValidDate(year + 1900, month, day) && !isValidDate(year + 2000, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Validate place of birth
  const placeOfBirth = value.substr(6, 2)
  const notAvailablePlaces = ['17', '18', '19', '20', '69', '70', '73', '80', '81', '94', '95', '96', '97']
  return {
    meta: {},
    valid: notAvailablePlaces.indexOf(placeOfBirth) === -1
  }
}

function validateMX (value:string) : { meta:unknown, valid:boolean } {
  const v = value.toUpperCase()
  if (!/^[A-Z]{4}\d{6}[A-Z]{6}[0-9A-Z]\d$/.test(v)) {
    return {
      meta: {},
      valid: false
    }
  }
  const blacklistNames = [
    'BACA', 'BAKA', 'BUEI', 'BUEY', 'CACA', 'CACO', 'CAGA', 'CAGO', 'CAKA', 'CAKO', 'COGE', 'COGI', 'COJA',
    'COJE', 'COJI', 'COJO', 'COLA', 'CULO', 'FALO', 'FETO', 'GETA', 'GUEI', 'GUEY', 'JETA', 'JOTO', 'KACA',
    'KACO', 'KAGA', 'KAGO', 'KAKA', 'KAKO', 'KOGE', 'KOGI', 'KOJA', 'KOJE', 'KOJI', 'KOJO', 'KOLA', 'KULO',
    'LILO', 'LOCA', 'LOCO', 'LOKA', 'LOKO', 'MAME', 'MAMO', 'MEAR', 'MEAS', 'MEON', 'MIAR', 'MION', 'MOCO',
    'MOKO', 'MULA', 'MULO', 'NACA', 'NACO', 'PEDA', 'PEDO', 'PENE', 'PIPI', 'PITO', 'POPO', 'PUTA', 'PUTO',
    'QULO', 'RATA', 'ROBA', 'ROBE', 'ROBO', 'RUIN', 'SENO', 'TETA', 'VACA', 'VAGA', 'VAGO', 'VAKA', 'VUEI',
    'VUEY', 'WUEI', 'WUEY'
  ]
  const name = v.substr(0, 4)
  if (blacklistNames.indexOf(name) >= 0) {
    return {
      meta: {},
      valid: false
    }
  }

  // Check the date of birth
  let year = parseInt(v.substr(4, 2), 10)
  const month = parseInt(v.substr(6, 2), 10)
  const day = parseInt(v.substr(6, 2), 10)
  if (/^[0-9]$/.test(v.charAt(16))) {
    year += 1900
  } else {
    year += 2000
  }
  if (!isValidDate(year, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Check the gender
  const gender = v.charAt(10)
  if (gender !== 'H' && gender !== 'M') {
    // H for male, M for female
    return {
      meta: {},
      valid: false
    }
  }

  // Check the state
  const state = v.substr(11, 2)
  const states = [
    'AS', 'BC', 'BS', 'CC', 'CH', 'CL', 'CM', 'CS', 'DF', 'DG', 'GR', 'GT', 'HG', 'JC', 'MC', 'MN', 'MS',
    'NE', 'NL', 'NT', 'OC', 'PL', 'QR', 'QT', 'SL', 'SP', 'SR', 'TC', 'TL', 'TS', 'VZ', 'YN', 'ZS'
  ]
  if (states.indexOf(state) === -1) {
    return {
      meta: {},
      valid: false
    }
  }

  // Calculate the check digit
  const alphabet = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ'
  let sum = 0
  const length = v.length
  for (let i = 0; i < length - 1; i++) {
    sum += (18 - i) * alphabet.indexOf(v.charAt(i))
  }
  sum = (10 - sum % 10) % 10
  return {
    meta: {},
    valid: `${sum}` === v.charAt(length - 1)
  }
}

function validateME (value:string) : { meta:unknown, valid:boolean } {
  return {
    meta: {},
    valid: jmbg(value, 'ME')
  }
}

function validateNO (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{11}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Calculate the first check digit
  const firstCd = (v: string) => {
    const weight = [3, 7, 6, 1, 8, 9, 4, 5, 2]
    let sum = 0
    for (let i = 0; i < 9; i++) {
      sum += weight[i] * parseInt(v.charAt(i), 10)
    }
    return 11 - sum % 11
  }

  // Calculate the second check digit
  const secondCd = (v: string) => {
    const weight = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
    let sum = 0
    for (let i = 0; i < 10; i++) {
      sum += weight[i] * parseInt(v.charAt(i), 10)
    }
    return 11 - sum % 11
  }

  return {
    meta: {},
    valid: `${firstCd(value)}` === value.substr(-2, 1) && `${secondCd(value)}` === value.substr(-1)
  }
}

function validatePE (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{8}[0-9A-Z]*$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  if (value.length === 8) {
    return {
      meta: {},
      valid: true
    }
  }
  const weight = [3, 2, 7, 6, 5, 4, 3, 2]
  let sum = 0
  for (let i = 0; i < 8; i++) {
    sum += weight[i] * parseInt(value.charAt(i), 10)
  }
  const cd = sum % 11
  const checkDigit = [6, 5, 4, 3, 2, 1, 1, 0, 9, 8, 7][cd]
  const checkChar = 'KJIHGFEDCBA'.charAt(cd)
  return {
    meta: {},
    valid: value.charAt(8) === `${checkDigit}` || value.charAt(8) === checkChar
  }
}

function validatePL (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{11}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }

  let sum = 0
  const length = value.length
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7]

  for (let i = 0; i < length - 1; i++) {
    sum += weight[i] * parseInt(value.charAt(i), 10)
  }
  sum = sum % 10
  if (sum === 0) {
    sum = 10
  }
  sum = 10 - sum

  return {
    meta: {},
    valid: `${sum}` === value.charAt(length - 1)
  }
}

function validateRO (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{13}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const gender = parseInt(value.charAt(0), 10)
  if (gender === 0 || gender === 7 || gender === 8) {
    return {
      meta: {},
      valid: false
    }
  }

  // Determine the date of birth
  let year = parseInt(value.substr(1, 2), 10)
  const month = parseInt(value.substr(3, 2), 10)
  const day = parseInt(value.substr(5, 2), 10)
  // The year of date is determined base on the gender
  const centuries = [
    0, // Skip index 0
    1900, // Male born between 1900 and 1999
    1900, // Female born between 1900 and 1999
    1800, // Male born between 1800 and 1899
    1800, // Female born between 1800 and 1899
    2000, // Male born after 2000
    2000 //  Female born after 2000
  ]
  if (day > 31 && month > 12) {
    return {
      meta: {},
      valid: false
    }
  }
  if (gender !== 9) {
    year = centuries[gender] + year
    if (!isValidDate(year, month, day)) {
      return {
        meta: {},
        valid: false
      }
    }
  }

  // Validate the check digit
  let sum = 0
  const weight = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9]
  const length = value.length
  for (let i = 0; i < length - 1; i++) {
    sum += parseInt(value.charAt(i), 10) * weight[i]
  }
  sum = sum % 11
  if (sum === 10) {
    sum = 1
  }
  return {
    meta: {},
    valid: `${sum}` === value.charAt(length - 1)
  }
}

function validateRS (value:string) : { meta:unknown, valid:boolean } {
  return {
    meta: {},
    valid: jmbg(value, 'RS')
  }
}

function validateSI (value:string) : { meta:unknown, valid:boolean } {
  return {
    meta: {},
    valid: jmbg(value, 'SI')
  }
}

function validateZA (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{10}[0|1][8|9][0-9]$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  let year = parseInt(value.substr(0, 2), 10)
  const currentYear = new Date().getFullYear() % 100
  const month = parseInt(value.substr(2, 2), 10)
  const day = parseInt(value.substr(4, 2), 10)
  year = (year >= currentYear) ? (year + 1900) : (year + 2000)

  if (!isValidDate(year, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Validate the last check digit
  return {
    meta: {},
    valid: luhn(value)
  }
}

function validateES (value:string) : { meta:unknown, valid:boolean } {
  const isDNI = /^[0-9]{8}[-]{0,1}[A-HJ-NP-TV-Z]$/.test(value)
  const isNIE = /^[XYZ][-]{0,1}[0-9]{7}[-]{0,1}[A-HJ-NP-TV-Z]$/.test(value)
  const isCIF = /^[A-HNPQS][-]{0,1}[0-9]{7}[-]{0,1}[0-9A-J]$/.test(value)
  if (!isDNI && !isNIE && !isCIF) {
    return {
      meta: {},
      valid: false
    }
  }

  let v = value.replace(/-/g, '')
  let check
  let tpe
  let isValid = true
  if (isDNI || isNIE) {
    tpe = 'DNI'
    const index = 'XYZ'.indexOf(v.charAt(0))
    if (index !== -1) {
      // It is NIE number
      v = index + v.substr(1) + ''
      tpe = 'NIE'
    }

    check = parseInt(v.substr(0, 8), 10)
    check = 'TRWAGMYFPDXBNJZSQVHLCKE'[check % 23]
    return {
      meta: {
        type: tpe
      },
      valid: (check === v.substr(8, 1))
    }
  } else {
    check = v.substr(1, 7)
    tpe = 'CIF'
    const letter = v[0]
    const control = v.substr(-1)
    let sum = 0

    // The digits in the even positions are added to the sum directly.
    // The ones in the odd positions are multiplied by 2 and then added to the sum.
    // If the result of multiplying by 2 is 10 or higher, add the two digits
    // together and add that to the sum instead
    for (let i = 0; i < check.length; i++) {
      if (i % 2 !== 0) {
        sum += parseInt(check[i], 10)
      } else {
        const tmp = '' + (parseInt(check[i], 10) * 2)
        sum += parseInt(tmp[0], 10)
        if (tmp.length === 2) {
          sum += parseInt(tmp[1], 10)
        }
      }
    }

    // The control digit is calculated from the last digit of the sum.
    // If that last digit is not 0, subtract it from 10
    let lastDigit = sum - (Math.floor(sum / 10) * 10)
    if (lastDigit !== 0) {
      lastDigit = 10 - lastDigit
    }

    if ('KQS'.indexOf(letter) !== -1) {
      // If the CIF starts with a K, Q or S, the control digit must be a letter
      isValid = (control === 'JABCDEFGHI'[lastDigit])
    } else if ('ABEH'.indexOf(letter) !== -1) {
      // If it starts with A, B, E or H, it has to be a number
      isValid = (control === ('' + lastDigit))
    } else {
      // In any other case, it doesn't matter
      isValid = (control === ('' + lastDigit) || control === 'JABCDEFGHI'[lastDigit])
    }

    return {
      meta: {
        type: tpe
      },
      valid: isValid
    }
  }
}

function validateSE (value:string) : { meta:unknown, valid:boolean } {
  if (!/^[0-9]{10}$/.test(value) && !/^[0-9]{6}[-|+][0-9]{4}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const v = value.replace(/[^0-9]/g, '')
  const year = parseInt(v.substr(0, 2), 10) + 1900
  const month = parseInt(v.substr(2, 2), 10)
  const day = parseInt(v.substr(4, 2), 10)
  if (!isValidDate(year, month, day)) {
    return {
      meta: {},
      valid: false
    }
  }

  // Validate the last check digit
  return {
    meta: {},
    valid: luhn(v)
  }
}

function validateTH (value:string) : { meta:unknown, valid:boolean } {
  if (value.length !== 13) {
    return {
      meta: {},
      valid: false
    }
  }

  let sum = 0
  for (let i = 0; i < 12; i++) {
    sum += parseInt(value.charAt(i), 10) * (13 - i)
  }

  return {
    meta: {},
    valid: (11 - sum % 11) % 10 === parseInt(value.charAt(12), 10)
  }
}

function validateUY (value:string) : { meta:unknown, valid:boolean } {
  if (!/^\d{8}$/.test(value)) {
    return {
      meta: {},
      valid: false
    }
  }
  const weight = [2, 9, 8, 7, 6, 3, 4]
  let sum = 0
  for (let i = 0; i < 7; i++) {
    sum += parseInt(value.charAt(i), 10) * weight[i]
  }
  sum = sum % 10
  if (sum > 0) {
    sum = 10 - sum
  }
  return {
    meta: {},
    valid: `${sum}` === value.charAt(7)
  }
}

function isValidDate (year: number, month: number, day: number, notInFuture?: boolean): boolean {
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return false
  }

  if (year < 1000 || year > 9999 || month <= 0 || month > 12) {
    return false
  }
  const numDays = [
    31,
    // Update the number of days in Feb of leap year
    (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) ? 29 : 28,
    31, 30, 31, 30, 31, 31, 30, 31, 30, 31
  ]

  // Check the day
  if (day <= 0 || day > numDays[month - 1]) {
    return false
  }

  if (notInFuture === true) {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const currentDay = currentDate.getDate()
    return (year < currentYear || (year === currentYear && month - 1 < currentMonth) || (year === currentYear && month - 1 === currentMonth && day < currentDay))
  }

  return true
}

function jmbg (value: string, countryCode: 'BA' | 'MK' | 'ME' | 'RS' | 'SI') {
  if (!/^\d{13}$/.test(value)) {
    return false
  }
  const day = parseInt(value.substr(0, 2), 10)
  const month = parseInt(value.substr(2, 2), 10)
  // const year = parseInt(value.substr(4, 3), 10)
  const rr = parseInt(value.substr(7, 2), 10)
  const k = parseInt(value.substr(12, 1), 10)

  // Validate date of birth
  // FIXME: Validate the year of birth
  if (day > 31 || month > 12) {
    return false
  }

  // Validate checksum
  let sum = 0
  for (let i = 0; i < 6; i++) {
    sum += (7 - i) * (parseInt(value.charAt(i), 10) + parseInt(value.charAt(i + 6), 10))
  }
  sum = 11 - sum % 11
  if (sum === 10 || sum === 11) {
    sum = 0
  }
  if (sum !== k) {
    return false
  }

  // Validate political region
  // rr is the political region of birth, which can be in ranges:
  // 10-19: Bosnia and Herzegovina
  // 20-29: Montenegro
  // 30-39: Croatia (not used anymore)
  // 41-49: Macedonia
  // 50-59: Slovenia (only 50 is used)
  // 70-79: Central Serbia
  // 80-89: Serbian province of Vojvodina
  // 90-99: Kosovo
  switch (countryCode.toUpperCase()) {
    case 'BA': return (rr >= 10 && rr <= 19)
    case 'MK': return (rr >= 41 && rr <= 49)
    case 'ME': return (rr >= 20 && rr <= 29)
    case 'RS': return (rr >= 70 && rr <= 99)
    case 'SI': return (rr >= 50 && rr <= 59)
    default: return true
  }
}

function mod11And10 (value: string): boolean {
  const length = value.length
  let check = 5

  for (let i = 0; i < length; i++) {
    check = (((check || 10) * 2) % 11 + parseInt(value.charAt(i), 10)) % 10
  }

  return (check === 1)
}

function verhoeff (value: number[]): boolean {
  // Multiplication table d
  const d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  ]

  // Permutation table p
  const p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
  ]

  // Inverse table inv
  const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9]

  const invertedArray = value.reverse()
  let c = 0
  for (let i = 0; i < invertedArray.length; i++) {
    c = d[c][p[(i % 8)][invertedArray[i]]]
  }

  return c === 0
}

function luhn (value: string): boolean {
  let length = value.length
  const prodArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]]
  let mul = 0
  let sum = 0

  while (length--) {
    sum += prodArr[mul][parseInt(value.charAt(length), 10)]
    mul = 1 - mul
  }

  return (sum % 10 === 0 && sum > 0)
}
